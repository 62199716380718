import View from './components/View.js'
import './App.css';

function App() {
  return (
    <>
      <div className="global-bg"></div>
      <View />
    </>
  );
}

export default App;
